import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import MDX from "@mdx-js/runtime";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "./CodeBlock";
import Prism from "prism-react-renderer/prism";
import { ErrorBoundary } from "./ErrorBoundary";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-csharp");
require("prismjs/components/prism-java");

const Wrapper = styled.div`
  width: 100%;
  height:auto;
  
  padding: 1rem;

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style: initial;
  }

  //https://www.html5canvastutorials.com/blog/2012/06/custom-form-radio-checkbox/
  input[type="checkbox"] {
    -webkit-appearance: none;
    color: red !important;
    background-color: transparent;
    border: 1px solid #505050;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &:checked {
      //background-color: #e9ecee;

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      //color: #99a1a7;
      //color: ${(p) => p.theme.colors.secondary[0]};
    }

    &:checked:after {
      content: "\\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-size: 10px;
      position: absolute;
      top: 1px;
      left: 4px;
      color: ${(p) => p.theme.colors.secondary[1]};

      &:active,
      &:checked:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  color: ${(p) => p.theme.colors.text};
  ${styleSystem()}
`;
const components = {
  pre: (props) => <div {...props} />,
  code: CodeBlock,
};
const NotePreview = ({ children, content, ...props }) => {
  if (!content) {
    return null;
  }
  return (
    <Wrapper {...props} className="note-preview scrollbar">
      <Inner className="markdown-body ">
        <ErrorBoundary content={content}>
          <MDXProvider components={components}>
            <MDX>{content}</MDX>
          </MDXProvider>
        </ErrorBoundary>
      </Inner>
    </Wrapper>
  );
};

const Inner = styled.div`
  width: 100%;
  height: auto;
  max-width: 1280px;
  margin: 0 auto;
`;
export default NotePreview;
