import styled from "styled-components";
import NoteList from "../NoteList/NoteList";
import NoteSection from "../NoteView/NoteView";
import { useSelector } from "react-redux";
import { selectIsMobileView } from "../../../layout/layoutSlice";
import {
  selectFilteredNoteIds,
  selectIsFullScreenNoteView,
  selectNoteIds,
  selectSelectedNote,
} from "../../store/notesSlice";
import NotesListHeader from "./NotesListHeader";
import { styleSystem } from "../../../toolkit/styleSystem";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  ${styleSystem}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${styleSystem}
`;

const NotesPage = ({ ...props }) => {
  const mobile = useSelector(selectIsMobileView);
  const selectedNote = useSelector(selectSelectedNote);
  const allNoteIds = useSelector(selectNoteIds);
  const filteredNoteIds = useSelector(selectFilteredNoteIds);
  const fullScreenNoteView = useSelector(selectIsFullScreenNoteView);
  const showNoteList = (mobile && !selectedNote) || !mobile;

  return (
    <Wrapper {...props}>
      {!fullScreenNoteView && (
        <ListContainer
          className="list-container"
          minWidth="20em"
          maxWidth={["", "", "33em"]}
          width={[1, 1, 5 / 12]}
        >
          {!mobile && <NotesListHeader />}
          {showNoteList && (
            <NoteList
              width="100%"
              flexGrow={1}
              selectedNoteId={selectedNote?.id}
              noteIds={filteredNoteIds || allNoteIds}
            />
          )}
        </ListContainer>
      )}
      {/*The mde container tries to push out ti fit the content width. Setting a width with flex grow overrides it
      forcing it to fit in the container
      */}
      <NoteSection flexGrow="1" />
    </Wrapper>
  );
};

export default NotesPage;
