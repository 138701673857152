export function createLogger(name) {
  return {
    info: (msg, ...args) => logInfo(`[${name}] ${msg}`, ...args),
    debug: (msg, ...args) => logDebug(`[${name}] ${msg}`, ...args),
    warn: (msg, ...args) => logWarn(`[${name}] ${msg}`, ...args),
    error: (msg, ...args) => logError(`[${name}] ${msg}`, ...args),
  }
}
function isDev() {
  // eslint-disable-next-line no-undef
  return typeof __DEV__ !== 'undefined'? __DEV__: false
}
export function createNullLogger(name) {
  return {
    info: () => undefined,
    warn: () => undefined,
    debug: () => undefined,
    error: () => undefined
  }
}
export function logInfo(...args) {
  // eslint-disable-next-line no-undef
  if( isDev()) {
    console.log(...args);
  }
}

export function logDebug(...args) {
  // eslint-disable-next-line no-undef
  if(isDev()) {
    console.debug(...args);
  }
}

export function logWarn(...args) {
  // eslint-disable-next-line no-undef
  if(isDev()) {
    console.warn(...args);
  }
}

export function logError(...args) {
  // eslint-disable-next-line no-undef
  if(isDev()) {
    console.error(...args);
  }
}