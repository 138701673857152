import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useEffect } from "react";
import { updateAttachmentsAsync } from "../../store";
import { useDispatch } from "react-redux";
import { getNextFilename } from "../../getters";

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result); // data url!
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${(p) => p.theme.colors.primary[0]};
  text-align: center;

  margin: auto auto;
  height: 70px;
  display: grid;
  place-content: center;

  .drop_zone {
    &__editor {
    }
  }

  ${styleSystem()}
`;

const DropZone = ({ note, ...props }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function paste(event) {
      event.preventDefault();
      const items = event.clipboardData || event.originalEvent.clipboardData;
      const files = {};
      for (const index in items) {
        const item = items[index];

        if (item instanceof FileList) {
          for (let i = 0; i < item.length; i++) {
            const file = item.item(i);

            const content = await readFile(file);
            let { name } = file;

            name = getNextFilename(note, name);
            files[name] = { content: content };
          }
        }
      }
      await dispatch(updateAttachmentsAsync(note, files));
    }

    window.addEventListener("paste", paste, false);
    return () => {
      window.removeEventListener("paste", paste, false);
    };
  }, [dispatch, note]);

  return (
    <Wrapper {...props}>
      <div className="drop-zone__label">PASTE HERE</div>
      <Editor spellCheck={false} contentEditable={true} />
    </Wrapper>
  );
};

const Editor = styled.div`
  caret-color: transparent;
  color: transparent;
  overflow: hidden;
  border: none;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: auto;
  height: auto;

  :focus-visible,
  focus {
    outline: none !important;
    border: none !important;
  }

  :focus {
    outline: none !important;
    border: none !important;
  }
`;
export default DropZone;
