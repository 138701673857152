export const CONTENT_FILENAME = "content.md";

export function getNoteFiles(note) {
  return Object.keys(note.files)
    .map((filename) => ({
      ...note.files[filename],
      noteId: note.id,
    }))
    .filter((x) => x.filename !== CONTENT_FILENAME);
}

export function hasAttachments(note) {
  return Object.keys(note.files).length > 1;
}

function splitFilename(filename) {
  const name = filename.replace(/\.[^/.]+$/, "");

  const extension = filename.substring(name.length);
  return { name, extension };
}

export function isShared(note) {
  return !!note.shareId;
}

export function getDescription(note) {
  return note.description;
}

export function getNextFilename(note, filename) {
  if (note.files[filename] == null) return filename;
  const { name, extension } = splitFilename(filename);
  for (let i = 1; ; i++) {
    const newFilename = `${name}_${i}${extension}`;
    if (note.files[newFilename] == null) {
      return newFilename;
    }
  }
}
