import styled from "styled-components";
import { useDispatch } from "react-redux";
import { noteSelectionCleared } from "../../store/notesSlice";
import SimpleMDE from "react-simplemde-editor";
import EasyMDE from "easymde";
import { useCallback, useEffect, useMemo, useState } from "react";
import { styleSystem } from "../../../toolkit/styleSystem";
import { logInfo } from "../../../../utils/log";
import useNoteEditor from "./useNoteEditor";

const Wrapper = styled.div`
  height: 100%;
  line-height: 1.5;
  max-width: 100%;
  ${(p) => (!p.visible ? "visibility:hidden;" : "")}
  //overflow: scroll;
  ${styleSystem()}
  // Disable IOS zoom while editing.
  textarea {
    font-size: 16px;
  }

  // Make easymde height 100%
  & > div {
    height: 100%;
  }

  .EasyMDEContainer {
    height: 100%;
  }

  .CodeMirror {
    height: 100%;
  }
`;

const NoteEditorWindow = ({ getController, setHasUnsaved, ...props }) => {
  const dispatch = useDispatch();
  const [codemirrorInstance, setCodemirrorInstance] = useState(null);

  const [mde, setMde] = useState();

  const editor = useMemo(() => {
    const setEditorValue = (value) => mde.value(value);

    const getEditorValue = () => mde?.codemirror?.getTextArea()?.value;

    return { getEditorValue, setEditorValue };
  }, [mde]);

  const { performSave, onChange, revert, mobile, loaded } = useNoteEditor(
    mde && editor
  );

  const getCmInstanceCallback = useCallback((editor) => {
    setCodemirrorInstance(editor);
  }, []);

  useEffect(() => {
    if (codemirrorInstance) {
      logInfo("setting size");
      codemirrorInstance.setSize("100%", "100%");
    }
  }, [codemirrorInstance]);

  // get controller which is used to execute commands from parent controls.
  // This is necessary to drive the toolbar etc.
  useEffect(() => {
    getController &&
      getController({
        toggleHeadingSmaller() {
          EasyMDE.toggleHeadingSmaller(mde);
        },
        toggleItalic() {
          EasyMDE.toggleItalic(mde);
        },
        toggleBold() {
          EasyMDE.toggleBold(mde);
        },
        toggleStrikethrough() {
          EasyMDE.toggleStrikethrough(mde);
        },
        toggleBlockquote() {
          EasyMDE.toggleBlockquote(mde);
        },
        toggleCodeBlock() {
          EasyMDE.toggleCodeBlock(mde);
        },

        toggleUndo() {
          EasyMDE.toggleUndo(mde);
        },

        toggleRedo() {
          EasyMDE.toggleRedo(mde);
        },
        drawHorizontalRule() {
          EasyMDE.drawHorizontalRule(mde);
        },
        toggleOrderedList() {
          EasyMDE.toggleOrderedList(mde);
        },

        toggleUnorderedList() {
          EasyMDE.toggleUnorderedList(mde);
        },
        revert,
        async save() {
          await performSave();
          if (mobile) {
            dispatch(noteSelectionCleared());
          }
        },
      });
  }, [mde, performSave, mobile, revert, getController, dispatch]);

  // We need to memo this because passing new ref object to mde
  // will reinitialise the control
  const options = useMemo(
    () => ({
      autofocus: false,
      spellChecker: false,
      status: false,
      toolbar: false,
      forceSync: false,
    }),
    []
  );

  const onGetMde = useCallback((mde) => {
    logInfo("setting mde. Has Value", !!mde);
    setMde(mde);
  }, []);

  //  if (!note) return null;
  return (
    <Wrapper visible={loaded} {...props}>
      <SimpleMDE
        getCodemirrorInstance={getCmInstanceCallback}
        getMdeInstance={onGetMde}
        options={options}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default NoteEditorWindow;
