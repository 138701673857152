import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import NoteIcon from "@material-ui/icons/InsertDriveFile";
import SettingsIcon from "@material-ui/icons/Settings";
import Div from "../../toolkit/Div";
import { useHistory } from "react-router-dom";
import useRouting, { routes } from "../../../hooks/useRouting";
import BuildIcon from "@material-ui/icons/Build";
import { useDispatch, useSelector } from "react-redux";
import { addNoteAsync, syncNotesAsync } from "../../notes/store";
import IconButton from "./IconButton";
import CircleButton from "./CircleButton";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import SearchMenu from "./SearchMenu";
import { selectIsMobileView } from "../layoutSlice";
import Avatar from "./Avatar";
import ChangeTenant from "./ChangeTenant";

const Wrapper = styled.div`
  $activeColor: #ccc;
  $inactiveColor: #737373;
  width: ${(p) => p.theme.sizes.mainSideNavSmall};
  background-color: black;
  justify-content: start;
  align-items: center;
  display: flex;
  flex-direction: column;

  padding-top: 10px;
  height: 100%;
  .popover {
  }
`;
const SideNavContent = ({ ...props }) => {
  const history = useHistory();
  const { currentRoute } = useRouting();
  const dispatch = useDispatch();
  const mobile = useSelector(selectIsMobileView);
  const [search, setSearch] = useState();
  const [changeTenant, setChangeTenant] = useState(false);

  return (
    <Wrapper>
      <CircleButton mb={8} onClick={() => setChangeTenant((s) => !s)}>
        <Avatar></Avatar>
      </CircleButton>
      {changeTenant && <ChangeTenant close={() => setChangeTenant(false)} />}
      {/*Just need this for now as we have no dedicated mobile search*/}
      {(!mobile || true) && (
        <CircleButton
          backgroundColor="gray.7"
          color="gray.4"
          onClick={() => setSearch(true)}
          mb={8}
        >
          <SearchIcon style={{ width: "22px", height: "22px" }} />
        </CircleButton>
      )}
      {search && <SearchMenu close={() => setSearch(false)}></SearchMenu>}
      {!mobile && (
        <CircleButton
          onClick={() => dispatch(addNoteAsync())}
          mb={13}
          backgroundColor="secondary.1"
        >
          <AddIcon />
        </CircleButton>
      )}
      <IconButton
        active={currentRoute === "notes"}
        onClick={() => history.push(routes.notes)}
        icon={<NoteIcon />}
      />
      <IconButton
        active={currentRoute === "test"}
        onClick={() => history.push(routes.test)}
        icon={<BuildIcon />}
      />
      <Div
        display="flex"
        flexDirection="column"
        width={1}
        justifyContent="flex-end"
        flexGrow="1"
        mb={5}
      >
        <IconButton
          color="blue.5"
          onClick={() => dispatch(syncNotesAsync({ showSyncing: true, fullSync: true }))}
          icon={<RefreshIcon />}
        />

        <IconButton
          active={currentRoute === "settings"}
          onClick={() => history.push(routes.settings)}
          icon={<SettingsIcon />}
        />
      </Div>
    </Wrapper>
  );
};

export default SideNavContent;
