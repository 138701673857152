import {
  restoreNotesFromDbAsync,
  selectFirstNoteAsync,
} from "./notesSlice";
import { onFocusChange } from "../../../hooks/useFocusChanged";
import {
  activateLoadingLine,
  deactivateLoadingLine,
} from "../../layout/layoutSlice";
import isMobileView from "../../layout/isMobileView";
import config from "../../config/config";
import store from "../../../App/store";
import { useEffect } from "react";
import { syncNotesAsync } from "./actions";
const tenant = config.getTenant();

let synced = false;
export async function setup() {
  const dispatch = store.dispatch;
  const mobile = isMobileView();
  if (tenant) {
    if (synced) return;
    synced = true;

    onFocusChange(() => {
      dispatch(syncNotesAsync({ showSyncing: true }));
    });

    try {
      dispatch(activateLoadingLine());
      await dispatch(restoreNotesFromDbAsync());
      if (!mobile) {
        await dispatch(selectFirstNoteAsync());
      }
      // Do a quick sync first to get updates then do a full sync in background on load.
      // Dont need to do a full sync if the first sync is a full sync

      const performedFullSync = await dispatch(
        syncNotesAsync({ showSyncing: true })
      );

      !performedFullSync &&
        (await dispatch(syncNotesAsync({ fullSync: true })));
    } finally {
      dispatch(deactivateLoadingLine());
    }
  }
}

export const SetupSync = () => {
  useEffect(() => {
    setup();
  }, []);
  return null;
};
