import { isSafari } from "../../../../utils/browser";
import styled from "styled-components";

export const Video = ({ src }) => {
  return (
    <>
      {isSafari ? (
        <StyledImg src={src} />
      ) : (
        <StyledVideo src={src} autoPlay loop controls>
          Unsupported
        </StyledVideo>
      )}
    </>
  );
};
const StyledImg = styled.img`
  max-width: 100%;
  width: fit-content;
  height: auto;
  max-height: 100%;
`;
const StyledVideo = styled.video`
  max-width: 100%;
  width: fit-content;
  max-height: 100%;
`;
