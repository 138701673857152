import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";

const colors = {
  activeColor: "#ccc",
  inactiveColor: "#737373",
};

const StyledIconButton = styled.button`
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${(p) => (p.active ? colors.activeColor : colors.inactiveColor)};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: #333;
    }
  }

  ${styleSystem()}
`;

function IconButton({ icon, ...props }) {
  return <StyledIconButton {...props}>{icon}</StyledIconButton>;
}

export default IconButton;
