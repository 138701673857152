import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html,body {
    -webkit-user-drag: none;
  }
  html {
  }
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
    /*try stop background scrolling*/
    overflow:hidden;
    /*Maybe i can allow zoom now the the layout isnt screwed. Lets try*/
    /*https://stackoverflow.com/questions/4389932/how-do-you-disable-viewport-zooming-on-mobile-safari*/
    /*touch-action: pan-x pan-y;*/
    /*touch-action: none;*/
    background-color:black;

  }

  body * {
    /*font-size: 0.875rem;*/
    font-size: inherit;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  html {
    
  }
  body, #root {
    /*min-height: 100vh;*/
    height:100%;
    min-height: fit-content;
  }

  button, input, select, textarea {
    color: inherit;
  }

  /* fonts */
  body, input, button, select, textarea {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  }

  a {
    color: #00a3f4;
    text-decoration: none;
  }
  /*reusable stuff */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  /* Strip styling */
  button {
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
  }
  
  .scrollbar {
    overflow-y: auto !important;

    ::-webkit-scrollbar-track {
      background-color: ${(p) => p.theme.colors.primary[2]};
    }

    ::-webkit-scrollbar {
      width: ${(p) => p.theme.sizes.scrollbarTrack} !important;
      height: ${(p) => p.theme.sizes.scrollbarTrack} !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${(p) => p.theme.colors.primary[0]};
    }
  }
`;

export default GlobalStyles;
