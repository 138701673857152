import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Button from "../../../toolkit/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ExpandIcon from "@material-ui/icons/AspectRatio";
import { useDispatch } from "react-redux";
import { deleteAttachmentAsync } from "../../store";
import { useState } from "react";
import FullScreenPreview from "./FullScreenPreview";
import { downloadFile } from "../../../../utils/downloadFile";

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 288px;
  //border: 1px solid #545454;
  display: flex;
  flex-direction: column;
  padding: 0 0 0.5rem 0;
  justify-content: center;
  align-items: center;
  ${styleSystem()}
`;
const Title = styled.div`
  margin-top: 0.5rem;
  font-size: ${(p) => p.theme.fontSizes[0]};
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;
const DocumentContainer = ({
  src,
  attachment,
  isEditable,
  doc,
  title,
  ...props
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const onClick = () => {
    setFullScreen(true);
  };
  return (
    <Wrapper {...props}>
      {!fullScreen && <DocWrapper>{doc({ onClick })}</DocWrapper>}
      {fullScreen && (
        <FullScreenPreview
          onClose={() => setFullScreen(false)}
          filename={attachment.filename}
        >
          {doc({})}{" "}
        </FullScreenPreview>
      )}
      <Footer>
        <Title>{title}</Title>

        <ActionBar
          isEditable={isEditable}
          onDownload={() => downloadFile(src, attachment.filename)}
          toggleFullscreen={() => setFullScreen((x) => !x)}
          attachment={attachment}
        />
      </Footer>
    </Wrapper>
  );
};
const Footer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;
const StyledActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  ${styleSystem()}
`;
const ActionBar = ({
  onDownload,
  isEditable,
  attachment,
  toggleFullscreen,
  ...props
}) => {
  const dispatch = useDispatch();
  const deleteAttachment = () => {
    dispatch(deleteAttachmentAsync(attachment));
  };
  return (
    <StyledActionBar {...props}>
      {isEditable && (
        <Button onClick={deleteAttachment} variant="transparent">
          <DeleteIcon />
        </Button>
      )}
      <Button variant="transparent" onClick={onDownload}>
        <DownloadIcon />
      </Button>
      <Button onClick={toggleFullscreen} variant="transparent">
        <ExpandIcon />
      </Button>
    </StyledActionBar>
  );
};
const DocWrapper = styled.div`
  width: 100%;
  height: 1px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DocumentContainer;
