import styled from "styled-components";
import NoteEditorWindow from "./NoteEditorWindow";
import Div from "../../../toolkit/Div";
import Button from "../../../toolkit/Button";
import { useRef } from "react";

const toolbarHeight = 51;

const Toolbar = styled.div`
  height: 40px;
  width: 100%;
  padding: 7px 0 5px;
  font-size: 12px;
  display: flex;
  gap: 5px;
`;
const NoteEditor = ({ getController }) => {
  const controllerRef = useRef();

  function _setController(controller) {
    controllerRef.current = controller;
    getController && getController(controller);
  }

  return (
    <Div width="100%" height="100%" display="flex" flexDirection="column">
      <Toolbar height={toolbarHeight}>
        <Button
          variant="transparent"
          ml={10}
          onClick={() => controllerRef.current?.toggleHeadingSmaller()}
        >
          H
        </Button>
        <Button
          variant="transparent"
          onClick={() => controllerRef.current?.toggleBold()}
        >
          B
        </Button>

        <Button
          variant="transparent"
          onClick={() => controllerRef.current?.toggleItalic()}
        >
          I
        </Button>

        <Button
          variant="transparent"
          onClick={() => controllerRef.current?.drawHorizontalRule()}
        >
          --
        </Button>
        <Button
          variant="transparent"
          onClick={() => controllerRef.current?.toggleStrikethrough()}
        >
          ST
        </Button>

        {/*<Button*/}
        {/*  variant="transparent"*/}
        {/*  onClick={() => controller?.toggleUndo()}*/}
        {/*>*/}
        {/*  Undo*/}
        {/*</Button>*/}

        {/*<Button*/}
        {/*  variant="transparent"*/}
        {/*  onClick={() => controller?.toggleRedo()}*/}
        {/*>*/}
        {/*  Redo*/}
        {/*</Button>*/}
      </Toolbar>
      <Div width={1} height="0px" flexGrow="1">
        <NoteEditorWindow getController={_setController} />
      </Div>
    </Div>
  );
};

export default NoteEditor;
