import "./App.scss";
import CaptureAccessToken from "../features/config/CaptureAccessToken";

import { Redirect, Route, Switch } from "react-router-dom";
import FolderTreePage from "./FolderTreePage";
import NoteEditorWindow from "../features/notes/components/NoteEditor/NoteEditorWindow";
import styled from "styled-components";
import SideNav from "../features/layout/SideNav/SideNav";
import { routes } from "../hooks/useRouting";
import NotesPage from "../features/notes/components/NotesPage/NotesPage";
import TestPage from "./TestPage";
import MobileFooter from "../features/layout/MobileFooter/MobileFooter";
import theme from "../theme";
import { styleSystem } from "../features/toolkit/styleSystem";
import LoadingIndicator from "../features/layout/LoadingIndiator/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { selectPat } from "../features/config";
import MdxExample from "./Mdx";
import {
  formFactorChanged,
  selectIsEditMode,
} from "../features/notes/store/notesSlice";
import MobileSearch from "../features/notes/components/MobileSearch/MobileSearch";
import { selectIsMobileView } from "../features/layout/layoutSlice";
import { useEffect, useRef } from "react";
import SharedNote from "../features/notes/components/SharedNote/SharedNote";
import GlobalStyles from "../GlobalStyles";
import { SetupSync } from "../features/notes/store";

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.backgroundPrimary};
  color: ${(p) => p.theme.colors.text};
  width: 100%;
  display: flex;

  .app__right {
    position: relative;
  }


}

.app {
  &__right {
    flex-grow: 1;
  }
}

${styleSystem()}
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <LoadingIndicator />
      <Switch>
        <Route path={routes.shared}>
          <Wrapper>
            <SharedNote />
          </Wrapper>
        </Route>
        <Route path="*">
          <AppLayout />
        </Route>
      </Switch>
    </>
  );
}

export default App;

const AppLayout = () => {
  const pat = useSelector(selectPat);

  const mobile = useSelector(selectIsMobileView);
  const mobileRef = useRef(mobile);
  const dispatch = useDispatch();

  const isEditMode = useSelector(selectIsEditMode);
  const footerHeight = isEditMode ? 0 : theme.sizes.footerHeight;

  // Clear state because desktop filtered notes dont
  // apply to mobile
  useEffect(() => {
    if (mobile !== mobileRef.current) {
      mobileRef.current = mobile;
      dispatch(formFactorChanged());
    }
  }, [dispatch, mobile]);

  if (!pat)
    return (
      <Wrapper height="100vh">
        {" "}
        <CaptureAccessToken fullscreen={true} />
      </Wrapper>
    );
  return (
    <>
      <SetupSync />
      <Wrapper height="100vh" className='app-layout' pb={[footerHeight, footerHeight, 0]}>
        <div className="app__left">
          <MobileSearch />
          <SideNav />
        </div>
        <div className="app__right">
          <MainRoutes />
        </div>
      </Wrapper>

      <MobileFooter />
    </>
  );
};
const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/folder-tree">
        <FolderTreePage />
      </Route>
      <Route path={routes.settings}>
        <CaptureAccessToken />
      </Route>
      <Route path={routes.notes}>
        <NotesPage />
      </Route>
      <Route exact path="/mdx">
        <MdxExample />
      </Route>
      <Route exact path="/note-editor">
        <NoteEditorWindow content="hello world" />
      </Route>
      <route path="/test">
        <TestPage />
      </route>

      <Route path="*">
        <Redirect to={routes.notes} />
      </Route>
    </Switch>
  );
};
