import styled from 'styled-components'
import { styleSystem } from "./styleSystem";
import Div from "./Div";

const Wrapper = styled.div`
  overflow-y: auto;
  width:auto;
  height: 100%;
  ${styleSystem()}
`;

const Scrollable = ({maxWidth, center, children, ...props}) => {
  const centerStyles = center ? {display: 'flex', justifyContent: 'center'} : {};
  return <Wrapper className="scrollbar" {...props} style={{...centerStyles}}>
    <Div style={{maxWidth: maxWidth}}>
    {children}
    </Div>
  </Wrapper>
}

export default Scrollable