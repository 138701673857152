import styled from "styled-components";
import { styleSystem } from "../features/toolkit/styleSystem";
import MDX from "@mdx-js/runtime";
const Wrapper = styled.div`
  ${styleSystem()}
`;

const Test = styled.div`
  ${styleSystem()}
`;

const Test2 = () => <div>bah</div>;

const str = `
# hello world
## hello world 2
---
        
<Test fontSize={3}>Hi there</Test>
<Test2/>
`;
const MdxExample = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <MDX components={{ Test, Test2 }}>{str}</MDX>
    </Wrapper>
  );
};

export default MdxExample;
