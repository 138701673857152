import styled, { css } from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Button from "../../../toolkit/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  softDeleteNoteAsync,
  selectSelectedNote,
  noteSelectionCleared,
  selectIsEditMode,
  setEditingMode,
  selectSelectedContent,
  toggleFullScreenNoteView,
} from "../../store/notesSlice";
import Div from "../../../toolkit/Div";
import NoteEditor from "../NoteEditor/NoteEditor";
import { selectIsMobileView } from "../../../layout/layoutSlice";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useEffect, useRef, useState } from "react";
import PopupMenu, { MenuItem } from "../../../toolkit/PopupMenu";
import useDetectOutsideClick from "../../../../hooks/useDetectOutsideClick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NotePreview from "./NotePreview";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import AttachIcon from "@material-ui/icons/AttachFile";
import NotesIcon from '@material-ui/icons/Notes'

import FullScreenIcon from "@material-ui/icons/Fullscreen";
import Modal from "../../../toolkit/Modal";
import ShareDialog from "./ShareDialog";

import SharedIcon from "../SharedIcon";
import EditAttachments from "../Attachments/EditAttachments";
import Scrollable from "../../../toolkit/Scrollable";
import DocumentList from "../Attachments/DocumentList";
import { getNoteFiles, hasAttachments, isShared } from "../../getters";

const headerHeight = "44px";
const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.primary[3]};
  //Need this to stop container from overflowing the viewpoint width
  width: 0;
  ${(p) =>
    p.mobile
      ? css`
          position: fixed;
          z-index: 20;
          width: 100%;
          top: 0;
          height: unset;
          bottom: ${(p) => (p.editMode ? "0" : p.theme.sizes.footerHeight)};

          left: 0;
          right: 0;
        `
      : ""}
  ${styleSystem()}
`;

const HeaderBar = styled.div`
  height: ${headerHeight};
  display: flex;
  align-items: stretch;
  padding: 8px 12px 4px;
  justify-content: space-between;

  ${styleSystem()};
`;

const Body = styled.div`
  height: calc(100% - ${headerHeight});
  ${styleSystem()};
`;
const NoteSection = ({ ...props }) => {
  const note = useSelector(selectSelectedNote);
  const { data } = useSelector(selectSelectedContent) || {};
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const mobile = useSelector(selectIsMobileView);
  const morePopoverRef = useRef();
  const showMoreButtonRef = useRef();
  const controllerRef = useRef();
  const isEditMode = useSelector(selectIsEditMode);
  const [showShare, setShowShare] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    setShowAttachments(false);
  }, [note?.id]);
  useDetectOutsideClick(
    (isOutsideClick) => {
      isOutsideClick && setShowMore(false);
    },
    morePopoverRef,
    showMoreButtonRef
  );

  function deleteNote() {
    note && dispatch(softDeleteNoteAsync(note, !mobile));
    setShowMore(false);
  }

  if (!note) {
    return null;
  }

  return (
    <>
      {showShare && (
        <Modal onBackgroundClick={() => setShowShare(false)}>
          <ShareDialog />
        </Modal>
      )}
      <Wrapper
        mobile={mobile}
        editMode={isEditMode}
        className="note-section"
        {...props}
      >
        <HeaderBar>
          {mobile && !isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(noteSelectionCleared())}
            >
              <ArrowBackIosIcon
                style={{ position: "relative", right: "-5px" }}
              />
            </Button>
          )}

          {mobile && isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(false))}
            >
              <CheckIcon style={{ position: "relative" }} />
            </Button>
          )}

          {!mobile && !isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(!isEditMode))}
            >
              <EditIcon style={{ position: "relative" }} />
            </Button>
          )}
          {!mobile && isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(!isEditMode))}
            >
              <CheckIcon style={{ position: "relative" }} />
            </Button>
          )}

          <Div
            display="flex"
            alignItems="center"
            gap="10px"
            justifyContent="flex-end"
            flexGrow={1}
          >
            {isShared(note) && <SharedIcon />}
            <Button onClick={() => setShowShare(true)}>Share</Button>
            <Divider />
            <Button
              variant="transparent"
              color={hasAttachments(note) && !showAttachments ? "secondary.1" : "text"}
              onClick={() => setShowAttachments((att) => !att)}
            >
              {!showAttachments && <AttachIcon />}
              {showAttachments && <NotesIcon />}
            </Button>
            <Button
              variant="transparent"
              onClick={() => dispatch(toggleFullScreenNoteView())}
            >
              <FullScreenIcon />
            </Button>
            <Button
              ref={showMoreButtonRef}
              variant="transparent"
              onClick={() => setShowMore((state) => !state)}
            >
              <MoreHorizIcon />
            </Button>
            {showMore && (
              <PopupMenu ref={morePopoverRef} top={16}>
                <MenuItem onClick={deleteNote}>Delete</MenuItem>
              </PopupMenu>
            )}
          </Div>
        </HeaderBar>
        <Body width="100%">
          {showAttachments ? (
            <EditAttachments />
          ) : isEditMode ? (
            <NoteEditor getController={(x) => (controllerRef.current = x)} />
          ) : (
            <Scrollable>
              <NotePreview content={data}></NotePreview>
            </Scrollable>
          )}
        </Body>
      </Wrapper>
    </>
  );
};

const Divider = styled.div`
  border-right: 1px solid ${(p) => p.theme.colors.gray[5]};
  position: relative;
  right: -6px;
  height: 100%;
  //:after {
  //  display: block;
  //  content:'a';
  //}
`;

export default NoteSection;
