import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import { variant } from "styled-system";
import { Cancel } from "@material-ui/icons";
import Button from "./Button";

const StyledInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  ${variant({
    variants: {
      inset: {
        fontSize: "14px",
      },
    },
  })}

  ${styleSystem()}
`;

const Border = styled.div`
  border: 1px solid;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5em 0.7em;
  ${variant({
    variants: {
      inset: {
        backgroundColor: "primary.3",
        border: 0,
        padding: "0.3em 0.7em",
        borderRadius: "999px",
      },
    },
  })}
  ${styleSystem()}
`;

export default React.forwardRef(function Input(
  {
    inputProps,
    variant,
    focusOnLoad,
    onClear,
    prefix,
    suffix,
    width,
    ...props
  },
  ref
) {
  const otherRef = useRef();
  ref = ref || otherRef;
  function onCancel() {
    if (ref && ref.current) {
      ref.current.value = "";
    }
  }
  useEffect(() => {
    focusOnLoad && ref?.current?.focus();
  }, []);
  return (
    <Border {...props} variant={variant} onClick={() => ref?.current?.focus()}>
      {prefix}
      <StyledInput {...inputProps} variant={variant} ref={ref} />
      <Button variant="clear" onClick={onCancel}>
        {ref?.current?.value && (
          <Cancel style={{ width: "15px", height: "15px" }} />
        )}
      </Button>
      {suffix}
    </Border>
  );
});
