import localforage from "localforage";
import * as search from "./searchDb";
import toString from "../../utils/toString";
import storage from "../../utils/storage";
import config from "../config/config";
import { createLogger } from "../../utils/log";
import { gistTypes } from "./github";

const log = createLogger("db");
const tenant = storage.getTenant();

const storeNames = [
  `note-config`,
  `notes`,
  `note-content`,
  `note-content-versions`,
];
const schema = config.getSchemaVersion();

log.info("schema version ", schema);
if (schema === 1) {
  storeNames.forEach((name) => localforage.dropInstance({ name }));
  log.info("migrating from schema v1");
  config.incrementSchemaVersion();
  log.info("new schema version ", schema);
}

const configStore = localforage.createInstance({
  name: `${tenant}|note-config`,
});

const notesStore = localforage.createInstance({
  name: `${tenant}|notes`,
});

const noteContentStore = localforage.createInstance({
  name: `${tenant}|note-content`,
});

const noteContentVersionsStore = localforage.createInstance({
  name: `${tenant}|note-content-versions`,
});

let searchIndexInitialised = false;

export async function initialiseSearchIndex(notes) {
  if (searchIndexInitialised) return;
  searchIndexInitialised = true;
  log.info("indexing search");

  await noteContentStore.iterate((content, key) => {

    try {
      const note = notes[key];
      const additionalTags = [];
      if (note && !note.deleted) {
        if(note.shareId) {
          additionalTags.push('#shared');
        }
        if(Object.keys(note.files).length > 1) {
          additionalTags.push('#attachments');
        }
        const suffix = ` ${additionalTags.join(' ')}`;
        search.add(key, toString(content.data) + suffix);
      }
    } catch(err) {
      log.error('search for note failed', key, err);
    }

  });

  log.info("Finished indexing search");
}

export async function setNotes(...notes) {
  const promises = notes.map((n) => notesStore.setItem(n.id, n));

  await Promise.all(promises);
}

export async function patchNoteContent(id, { deleted, tags, title }) {
  const content = await noteContentStore.getItem(id);
  if (content) {
    if (deleted != null) content.deleted = deleted;
    if (tags != null) content.tags = tags;
    if (title != null) content.title = title;
    await noteContentStore.setItem(id, content);
    if (deleted) search.remove(id);
  }
}

export async function setNoteContent(note, data) {
  const contentWithMeta = {
    id: note.id,
    data,
    updated_at: note.updated_at,
    deleted: note.deleted,
    tags: note.tags,
    title: note.title,
  };
  await noteContentStore.setItem(note.id, contentWithMeta);
  await noteContentVersionsStore.setItem(
    generateVersionKey(contentWithMeta),
    true
  );
  search.add(note.id, contentWithMeta.data);
  return contentWithMeta;
}

export async function hasNoteContent(note) {
  const key = generateVersionKey(note);
  const exists = await noteContentVersionsStore.getItem(key);
  return !!exists;
}

export function generateVersionKey({ id, updated_at }) {
  return `${id}|${updated_at}`;
}

export async function getAllNotes() {
  let batch = [];
  await notesStore.iterate((note) => {
    // Shared notes could get into the db because
    // of old and new versions running at the same time.
    if(!note.type  || note.type === gistTypes.note)
    batch.push(note);
  });

  return batch;
}

export async function getNoteById(id) {
  return await notesStore.getItem(id);
}

export async function deleteNote(id) {
  await noteContentStore.removeItem(id);
  await notesStore.removeItem(id);
  const keys = await noteContentVersionsStore.keys();
  for (let key of keys) {
    if (key.startsWith(id)) {
      await noteContentVersionsStore.removeItem(key);
    }
  }
  search.remove(id);
}

export async function getLastUpdatedTimestamp() {
  return await configStore.getItem("lastUpdatedTimestamp");
}

export async function setLastUpdatedTimestamp(timestamp) {
  await configStore.setItem("lastUpdatedTimestamp", timestamp);
}
