import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Portal from "../../../toolkit/Portal";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../../toolkit/Button";
import Div from "../../../toolkit/Div";
const Wrapper = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${styleSystem()}
`;

const FullScreenPreview = ({ onClose, filename, children, ...props }) => {
  return (
    <Portal>
      <Wrapper {...props}>
        <Header color="text" px={8}>
          <Div fontSize={0}>{filename}</Div>
          <div></div>
          <Button variant="transparent" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Header>
        <Content onClick={onClose}>
          <File
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </File>
        </Content>
      </Wrapper>
    </Portal>
  );
};
// const Doc = styled.div`
// position
// `;
const File = styled.div`
  display: contents;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  background-color: rgb(0, 0, 0, 0.9);
`;
const Header = styled.div`
  height: 50px;
  background-color: #4f4f4f;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${styleSystem()}
`;
export default FullScreenPreview;
