import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const Wrapper = styled.span`
  line-height: 0;
  color: ${(p) => p.theme.colors.secondary[1]} ${styleSystem()};
`;

const ListItemIcon = ({children, ...props }) => {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  );
};

export default ListItemIcon;
