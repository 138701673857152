import styled from "styled-components";
import { Img } from "./Img";
import { Pdf } from "./Pdf";
import { Video } from "./Video";
import { Audio } from "./Audio";
import DescriptionIcon from "@material-ui/icons/Description";

export const DocumentViewer = ({ onClick, type, src }) => {
  if (type.startsWith("image/")) return <Img onClick={onClick} src={src}></Img>;
  if (type === "application/pdf") return <Pdf src={src} />;
  if (type === "application/mp4") return <Video src={src} />;
  if (type.startsWith("audio/")) return <Audio src={src} />;
  return (
    <GenericDoc>
      <DescriptionIcon style={{ width: "100%", height: "100%" }} />
    </GenericDoc>
  );
};

const GenericDoc = styled.div`
  width: 200px;
  height: 200px;
`;

export default Document;
