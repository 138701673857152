import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { Document } from "./Document";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  ${styleSystem()}
`;

const DocumentList = ({ attachments, isEditable, ...props }) => {
  if (!attachments) return null;
  return (
    <Wrapper className="document-list" {...props}>
      {attachments.map((x) => (
        <Document
          isEditable={isEditable}
          key={x.filename}
          attachment={x}
        ></Document>
      ))}
    </Wrapper>
  );
};

export default DocumentList;
