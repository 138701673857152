import { useEffect, useState } from "react";
import * as api from "../../github/noteApi";
import DocumentContainer from "./DocumentContainer";
import { DocumentViewer } from "./DocumentViewer";

export const Document = ({ isEditable, attachment }) => {
  const [source, setSource] = useState();
  useEffect(() => {
    (async () => {
      const data = await api.getAttachment(attachment);
      const blob = await (await fetch(data)).blob();
      const url = URL.createObjectURL(blob);

      setSource(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    })();
  }, [attachment]);
  return (
    <DocumentContainer
      src={source}
      isEditable={isEditable}
      className="document"
      attachment={attachment}
      doc={({ onClick }) => (
        <DocumentViewer onClick={onClick} type={attachment.type} src={source} />
      )}
      title={attachment.filename}
    />
  );
};
