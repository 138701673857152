const breakpoints = ["640px", "768px", "1024px", "1280px", "1536px"];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];
breakpoints.mobile = breakpoints[1];
breakpoints.isMobileView = (width) => width < 768; //breakpoints.mobile;

const sizes = [];
sizes.mainSideNavSmall = 60;
sizes.footerHeight = "4rem";
sizes.scrollbarTrack = "8px";

const fontWeights = [400, 600, 700];
fontWeights.body = 400;
fontWeights.heading = 700;
fontWeights.semibold = 600;
fontWeights.bold = 700;

const colors = {
  black: "#000000",
  white: "#fff",
  secondary: ["#26b54c", "#00a82d", "#008f26", "#007620"],
  anchor: '#00a3f4',
  primary: ["#404040", "#333", "#1a1a1a", "#262626"],
  buttonBackgroundPrimary: "#00a82d",
  text: "#d9d9d9",
  navy: "#004175",
  background: "#fff",
  muted: "#f6f6f9",
  warning: "#b00020",
  alertSuccess: ["#d1e7dd", "#badbcc", "#0f5132"],
  alertDanger: ["#f8d7da", "#f5c2c7", "#842029"],
  highlight: "hsla(205, 100%, 40%, 0.125)",
  gray: [
    "#F9FAFB",
    "#F3F4F6",
    "#E5E7EB",
    "#D1D5DB",
    "#9CA3AF",
    "#6B7280",
    "#4B5563",
    "#374151",
    "#1F2937",
    "#111827",
  ],
  red: [
    "#FEF2F2",
    "#FEE2E2",
    "#FECACA",
    "#FCA5A5",
    "#F87171",
    "#EF4444",
    "#DC2626",
    "#B91C1C",
    "#991B1B",
    "#7F1D1D",
  ],
  green: [
    "#ECFDF5",
    "#D1FAE5",
    "#A7F3D0",
    "#6EE7B7",
    "#34D399",
    "#10B981",
    "#059669",
    "#047857",
    "#065F46",
    "#064E3B",
  ],

  blue: [
    "#EFF6FF",
    "#DBEAFE",
    "#BFDBFE",
    "#93C5FD",
    "#60A5FA",
    "#3B82F6",
    "#2563EB",
    "#1D4ED8",
    "#1E40AF",
    "#1E3A8A",
  ],
};
colors.backgroundPrimary = colors.primary[3];
colors.backgroundSecondary = colors.primary[2];
colors.notelistBorder = colors.primary[2];
colors.backgroundTertiary = colors.primary[1];
colors.pillBackground = colors.primary[0];
colors.highlight = colors.blue[4];

const theme = {
  breakpoints,
  colors,
  space: [
    "0px",
    "1px",
    "0.125rem",
    "0.25rem",
    "0.375rem",
    "0.5rem",
    "0.625rem",
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.75rem",
    "2rem",
    "2.25rem",
    "2.5rem",
    "2.75rem",
    "3rem",
    "3.5rem",
    "4rem",
    "5rem",
    "6rem",
    "7rem",
    "8rem",
    "9rem",
    "10rem",
    "11rem",
    "12rem",
    "13rem",
    "14rem",
    "15rem",
    "16rem",
    "18rem",
    "20rem",
    "24rem",
  ],
  sizes,
  radii: {
    default: 3,
    circle: 99999,
  },
  fontSizes: [
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "32px",
    "48px",
    "64px",
    "96px",
  ],
  fontWeights,
};

export default theme;
