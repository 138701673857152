import { search } from "../../searchDb";
import { searchFiltered, selectNoteEntities } from "../notesSlice";

export function performSearchAsync(searchText) {
  return async (dispatch, getState) => {
    const notes = selectNoteEntities(getState());
    const filteredNoteIds = search(searchText).filter((id) => notes[id]);
    console.log();
    filteredNoteIds.sort((a, b) => {
      return notes[b].updated_at - notes[a].updated_at;
    });

    dispatch(searchFiltered({ searchText, filteredNoteIds }));
  };
}
