import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as api from "../github";
import * as db from "../db";
import {
  activateLoadingLine,
  deactivateLoadingLine,
} from "../../layout/layoutSlice";
import { createLogger, logWarn } from "../../../utils/log";
import { extractDescription, extractTitle, isSavingNote } from "../utils";

import { search } from "../searchDb";
import { parseNoteGist } from "../github";

const log = createLogger("noteSlice");
export const name = "notes";

// const sortOptions = {
//   date: "date",
//   name: "name",
// };
// const sortDirection = {
//   asc: "asc",
//   desc: "desc",
// };
//
// const recordTypes = {
//   note: "n#",
//   noteDetails: "d#",
// };

const notesAdapterByDate = createEntityAdapter({
  selectId: (note) => note.id,
  sortComparer: (a, b) => b.updated_at - a.updated_at,
});

const noteContentAdapter = createEntityAdapter();

const updateNote = (state, note) => {
  // this needs to be here as its called by loading from indexdb too
  // and deleted notes are stored
  if (note.deleted) notesAdapterByDate.removeOne(state.notes, note.id);
  else notesAdapterByDate.setOne(state.notes, note);
};
const slice = createSlice({
  name,
  initialState: {
    notes: notesAdapterByDate.getInitialState(),
    contents: noteContentAdapter.getInitialState(),
    selectedNoteId: null,
    searchText: "",
    filteredNoteIds: null,
    state: "idle",
    deletingNoteIds: {},
    savingNotes: {},
    editMode: false,
    searching: false,
    fullScreenNoteView: false,
  },
  reducers: {
    notesFetched: (state, action) => {
      for (let note of action.payload) {
        updateNote(state, note);
      }
    },

    openSearch: (state) => {
      state.searching = true;
      state.selectedNoteId = null;
    },
    closeSearch: (state) => {
      state.searching = false;
    },
    savingNote: (state, action) => {
      const { id, content } = action.payload;
      state.savingNotes[id] = { id, content };
    },

    toggleFullScreenNoteView: (state) => {
      state.fullScreenNoteView = !state.fullScreenNoteView;
    },
    setEditingMode(state, action) {
      state.editMode = action.payload;
    },

    noteContentLoaded: (state, action) => {
      const content = action.payload;
      if (content.id === state.selectedNoteId) {
        noteContentAdapter.setOne(state.contents, content);
      }
    },
    searchFiltered: (state, action) => {
      const { searchText, filteredNoteIds } = action.payload;
      state.searchText = searchText;
      state.filteredNoteIds = filteredNoteIds;
      state.fullScreenNoteView = false;
    },

    clearSearchFilter: (state) => {
      state.searchText = "";
      state.filteredNoteIds = null;
    },

    syncNotes: (state) => {
      state.state = "syncing";
    },
    syncNotesComplete: (state) => {
      state.state = "idle";
    },

    noteCreated: (state, action) => {
      const note = action.payload;
      notesAdapterByDate.addOne(state.notes, note);
    },
    noteUpdated: (state, action) => {
      const note = action.payload;
      updateNote(state, note);
    },
    noteSaved: (state, action) => {
      const { note, content } = action.payload;
      updateNote(state, note);
      noteContentAdapter.setOne(state.contents, content);
      state.savingNotes[note.id] = false;
    },
    deletingNote: (state, action) => {
      const id = action.payload;
      state.deletingNoteIds[id] = true;
    },
    formFactorChanged: (state) => {
      state.searchText = "";
      state.filteredNoteIds = null;
    },
    noteDeleted: (state, action) => {
      const note = action.payload;
      updateNote(state, note);
      state.selectedNoteId = null;
    },
    noteSelected: (state, action) => {
      const note = action.payload;
      if (note?.id !== state.selectedNoteId) {
        state.editMode = false;
      }
      state.selectedNoteId = note.id;

      // set content to null to prevent note attached to wrong content
      noteContentAdapter.removeOne(state.contents, note.id);
    },
    noteSelectionCleared: (state) => {
      state.selectedNoteId = null;
    },
    setSelectedNoteContent: (state, action) => {
      noteContentAdapter.setOne(state.contents, action.payload);
    },
  },
});

export const selectState = (state) => state[name];
const noteSelectors = notesAdapterByDate.getSelectors();
const noteContentSelectors = noteContentAdapter.getSelectors();

export const selectNoteIds = createSelector(selectState, (state) =>
  noteSelectors.selectIds(state.notes)
);

export const selectFilteredNoteIds = createSelector(
  selectState,
  (state) => state.filteredNoteIds
);

export const selectNoteIdsToDisplay = (state) => {
  const filteredNotes = selectFilteredNoteIds(state);
  if (filteredNotes) {
    return filteredNotes;
  }
  return selectNoteIds(state);
};

export const selectIsNoteSearch = createSelector(selectState, (state) => {
  return !!state.filteredNoteIds;
});

export const selectNotes = createSelector(selectState, (state) =>
  noteSelectors.selectAll(state.notes)
);

export const selectNoteEntities = createSelector(selectState, (state) =>
  noteSelectors.selectEntities(state.notes)
);

export const selectSelectedNote = createSelector(selectState, (state) => {
  return noteSelectors.selectById(state.notes, state.selectedNoteId);
});

export const selectSelectedContent = createSelector(selectState, (state) => {
  return noteContentSelectors.selectById(state.contents, state.selectedNoteId);
});
export const selectNoteWithContent = createSelector(selectState, (state) => {
  const note = noteSelectors.selectById(state.notes, state.selectedNoteId);
  const content = noteContentSelectors.selectById(
    state.contents,
    state.selectedNoteId
  );
  if (!note || !content) return {};
  return { note, content };
});

// we need this because with auto save enabled, we can have a race condition
// were a user clicks delete then changes selected note. If save happens after delete
// the note is undeleted.
export const selectIsDeletingSelectedNote = createSelector(
  selectState,
  (state) => !!state.deletingNoteIds[state.selectedNoteId]
);

export const selectIsSearching = createSelector(
  selectState,
  (state) => state.searching
);

export const selectIsEditMode = createSelector(
  selectState,
  (state) => state.editMode
);

export const selectSavingNotes = createSelector(
  selectState,
  (state) => state.savingNotes
);

export const selectSearchText = createSelector(
  selectState,
  (s) => s.searchText
);

export const selectIsFullScreenNoteView = createSelector(
  selectState,
  (s) => s.fullScreenNoteView
);

export const {
  notesFetched,
  setEditingMode,
  toggleFullScreenNoteView,
  syncNotes,
  syncNotesComplete,
  noteCreated,
  noteSelected,
  setSelectedNoteContent,
  noteUpdated,
  noteDeleted,
  noteSelectionCleared,
  searchFiltered,
  clearSearchFilter,
  openSearch,
  closeSearch,
  deletingNote,
  noteContentLoaded,
  savingNote,
  noteSaved,
  setSearching,
  formFactorChanged,
} = slice.actions;

function dispatchNoteUpdated(dispatch, note) {
  db.setNotes(note);
  dispatch(noteUpdated(note));
}

export function shareNoteAsync(note) {
  return async (dispatch) => {
    const { updatedNote, noteShare } = await api.createNoteShare({ note });
    dispatchNoteUpdated(dispatch, updatedNote);
  };
}

export function updateAttachmentsAsync(note, attachments) {
  return async (dispatch) => {
    try {
      dispatch(activateLoadingLine());
      const gist = await api.updateAttachments(note.id, attachments);

      const updatedNote = parseNoteGist(gist);
      dispatchNoteUpdated(dispatch, updatedNote);

      if (note.shareId) {
        await api.updateAttachments(note.shareId, attachments);
      }
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}

export function restoreNotesFromDbAsync() {
  return async (dispatch) => {
    const notes = await db.getAllNotes();
    dispatch(notesFetched(notes));
  };
}

export function selectNoteAsync(note) {
  return async (dispatch, getState) => {
    const savingNotes = selectSavingNotes(getState());
    if (savingNotes[note.id]) {
      return;
    }
    try {
      dispatch(activateLoadingLine());
      const selNote = selectSelectedNote(getState());
      if (selNote?.id !== note.id) {
        dispatch(noteSelected(note));
        const content = await api.getNoteContent(note);
        dispatch(setSelectedNoteContent(content));
      }
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}

export function selectFirstNoteAsync() {
  return async (dispatch, getState) => {
    const notes = selectNotes(getState());
    if (notes[0]) return await dispatch(selectNoteAsync(notes[0]));
  };
}

function createNoteContent(id, data) {
  return { id, data };
}

export function saveNoteAsync(note, contentText) {
  return async (dispatch, getState) => {
    try {
      dispatch(activateLoadingLine());
      const id = note.id;
      if (isSavingNote(getState(), id)) {
        logWarn("multiple concurrent saves for same note", id);
      }

      const description = extractDescription(contentText);
      dispatch(savingNote({ id, content: contentText }));
      const title = extractTitle(contentText);
      const updatedNote = await api.setNoteContent(
        {
          ...note,
          title,
          description,
        },
        contentText
      );
      await db.setNotes(updatedNote);

      const content = createNoteContent(id, contentText);
      await db.setNoteContent(updatedNote, contentText);

      if (updatedNote.shareId) {
        await api.updateNoteShare({ note: updatedNote, content: contentText });
      }
      dispatch(noteSaved({ note: updatedNote, content }));
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}

export function hardDeleteNoteAsync(note) {
  return async () => {
    await api.deleteGist(note.id);
    await db.deleteNote(note.id);
  };
}

export function softDeleteNoteAsync(note, selectAfterDelete) {
  return async (dispatch) => {
    try {
      dispatch(activateLoadingLine());
      dispatch(deletingNote(note.id));
      note = { ...note, deleted: true };
      try {
        if (note.shareId) {
          await api.deleteGist(note.shareId);
        }
        const newNote = await api.updateNote({
          ...note,
          deleted: true,
          shareId: null,
        });
        await db.setNotes(newNote);
        await db.patchNoteContent(note.id, {
          deleted: true,
          tags: note.tags,
          title: note.title,
        });
        await dispatch(noteDeleted(note));
      } catch (err) {
        if (api.isNotFoundError(err)) {
          await dispatch(hardDeleteNoteAsync(note));
          return;
        } else throw err;
      }

      selectAfterDelete && (await dispatch(selectFirstNoteAsync()));
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}

export function addNoteAsync() {
  return async (dispatch) => {
    try {
      dispatch(activateLoadingLine());
      const note = await api.createNote({ title: "Untitled" });
      dispatch(noteCreated(note));
      await dispatch(selectNoteAsync(note));
      dispatch(setEditingMode(true));
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}

export default slice.reducer;
