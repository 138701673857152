import * as api from "../../github";
import * as db from "../../db";
import { noteUpdated, selectNoteEntities } from "../notesSlice";
import { parseNoteGist } from "../../github";
import { activateLoadingLine, deactivateLoadingLine } from "../../../layout/layoutSlice";

export function deleteAttachmentAsync(attachment) {
  return async (dispatch, getState) => {

    try {
      dispatch(activateLoadingLine());
      const payload = {
        [attachment.filename]: {},
      };
      const note = selectNoteEntities(getState())[attachment.noteId];

      if(note.shareId) {
        await api.updateAttachments(note.shareId, payload);
      }

      const gist = await api.updateAttachments(note.id, payload);

      const updatedNote = parseNoteGist(gist);
      await db.setNotes(updatedNote);
      dispatch(noteUpdated(updatedNote));

    } finally {
      dispatch(deactivateLoadingLine());
    }

  };
}
