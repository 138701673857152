import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useDispatch, useSelector } from "react-redux";

import { selectSelectedNote } from "../../store";
import { getNoteFiles } from "../../getters";
import DropZone from "./DropZone";
import DocumentList from "./DocumentList";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;

  h1 {
    font-size: ${(p) => p.theme.fontSizes[4]};
    font-weight: ${(p) => p.theme.fontWeights[1]};
  }

  ${styleSystem()}
`;

const EditAttachments = ({ ...props }) => {
  const note = useSelector(selectSelectedNote);
  const dispatch = useDispatch();
  const attachments = getNoteFiles(note);

  return (
    <Wrapper className="attachments scrollbar" {...props}>
      <h1>Attachments</h1>
      <DropZone my={10} note={note}></DropZone>
      <DocumentList isEditable attachments={attachments}></DocumentList>
    </Wrapper>
  );
};

const Row = styled.div`
  height: 10em;
  display: flex;
  align-items: center;
`;

export default EditAttachments;
