import { selectSavingNotes } from "./store/notesSlice";

export function extractDescription(text) {
  const grp = /.*?[a-z0-9].*?\n(.{0,100})/gims.exec(text);
  return ((grp || {})[1] || "").trim();
}

export function extractTitle(text) {
  const grp = /.*[a-z0-9].*/i.exec(text);
  return ((grp || {})[0] || "").replace(/#/g, "");
}

export function isSavingNote(state, id) {
  const savingNotes = selectSavingNotes(state);
  return !!savingNotes[id];
}
