import styled, { css } from "styled-components";
import { variant } from "styled-system";
import { styleSystem } from "./styleSystem";
import Spinner from "./Spinner";
import Div from "./Div";
import React from "react";

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 4px;
  padding: 5px 10px 5px 10px;

  @media (hover) and (pointer: fine) {
    :hover {
      background-color: ${(p) => p.hoverColor || p.theme.colors.primary[0]};
    }
  }
  ${variant({
    variants: {
      primary: {
        color: "black",
        backgroundColor: "buttonBackgroundPrimary",
        height: "32px",
        "&:hover": {
          backgroundColor: "secondary.0",
        },
      },
      edit: {
        color: "black",
        backgroundColor: "blue.5",
        height: "32px",
        "&:hover": {
          backgroundColor: "blue.4",
        },
      },
      secondary: {
        color: "black",
        backgroundColor: "gray.5",
        height: "32px",
        "&:hover": {
          backgroundColor: "gray.4",
        },
      },
      warning: {
        color: "text",
        backgroundColor: "warning",
        height: "32px",
        "&:hover": {
          backgroundColor: "warning",
        },
      },
      transparent: {
        color: "inherit",
        height: "unset",
        backgroundColor: "transparent",
        border: "none",
        padding: "0 3px",
        minWidth: "28px",
        "&:hover": {
          backgroundColor: "primary.0",
        },
      },
      clear: {
        color: "inherit",
        height: "unset",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        minWidth: "0",
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
  })}
  ${styleSystem()}
`;
StyledButton.defaultProps = { variant: "primary" };
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: white;
          opacity: 30%;
        `
      : css`
          :active {
            background-color: black;
            opacity: 10%;
          }
        `}
`;
const Button = React.forwardRef(
  ({ children, loading, disabled, ...props }, ref) => {
    const isDisabled = loading || disabled;
    return (
      <StyledButton ref={ref} {...props} disabled={isDisabled}>
        <Overlay disabled={isDisabled} />
        {loading && <Spinner fontSize={1} mr={5} />}{" "}
        <Div
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="body"
        >
          {children}
        </Div>
      </StyledButton>
    );
  }
);

export default Button;
