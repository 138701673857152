import { useCallback, useEffect, useRef, useState } from "react";
import { createLogger } from "../../../../utils/log";
import {
  saveNoteAsync,
  selectIsDeletingSelectedNote,
  selectNoteWithContent,
} from "../../store/notesSlice";
import { useOnBlur } from "../../../../hooks/useFocusChanged";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMobileView } from "../../../layout/layoutSlice";

// Auto save on x number of changes
const AUTO_SAVE_CHANGES = 200;

const log = createLogger("editor");
/**
 *
 * @param editor
 * returns the current editor, or null if the editor is not ready.
 * When the editor instance changes, reset the internal state
 *
 */
export default function useNoteEditor(editor) {
  const { note, content } = useSelector(selectNoteWithContent);
  const { setEditorValue } = editor || {};
  const loadedNoteText = content?.data;
  const mobile = useSelector(selectIsMobileView);
  const isDeleting = useSelector(selectIsDeletingSelectedNote);

  const dispatch = useDispatch();

  const [isResetting, setIsResetting] = useState(false);

  const lastSaveRef = useRef(0);
  const lastSavedValueRef = useRef();
  const editorRef = useRef();

  const isUnmountingRef = useRef();
  const [noteId, setNoteId] = useState();

  const editedCountRef = useRef(0);
  const editedContentRef = useRef();

  const noteIdChanged = noteId !== note?.id;

  const saveIfRequired = useCallback(async () => {
    const editorValue = editedContentRef.current;
    log.debug("save precheck", {
      savingValue: editorValue,
      lastSaved: lastSavedValueRef.current,
    });

    if (note && editorValue !== lastSavedValueRef.current && !isDeleting) {
      try {
        log.info("saving", { note, editorValue });

        lastSaveRef.current = Date.now();

        await dispatch(saveNoteAsync(note, editorValue));
        lastSavedValueRef.current = editorValue;
        log.info("save success", { lastSaved: editorValue });

        editedCountRef.current = 0;
      } finally {
      }
    }
  }, [note, isDeleting, dispatch]);

  useOnBlur(() => saveIfRequired());

  const revertCallback = useCallback(() => {
    if (!editor) return;
    editedCountRef.current = 0;
    setEditorValue(loadedNoteText);
  }, [loadedNoteText, setEditorValue, editor]);

  // resets the editor text from current values
  const resetEditText = useCallback(() => {
    const newNoteText = loadedNoteText || "";
    editedContentRef.current = null;
    setEditorValue(newNoteText);
    lastSavedValueRef.current = newNoteText;
    editedCountRef.current = 0;
  }, [loadedNoteText, setEditorValue]);

  // saves and transitions state on note id change
  const reset = useCallback(async () => {
    log.debug("resetting", {
      id: noteId,
      new: note?.id,
      hasEditor: !!setEditorValue,
      hasText: !!loadedNoteText,
    });

    if (noteId !== note?.id && !isResetting) {
      setIsResetting(true);
      try {
        await saveIfRequired();
        setNoteId(note?.id);
        resetEditText();
        log.info("reset note", note?.id);
      } finally {
        setIsResetting(false);
      }
    } else {
      log.debug("reset false");
    }
  }, [
    note,
    noteId,
    isResetting,
    setEditorValue,
    loadedNoteText,
    saveIfRequired,
    resetEditText,
  ]);

  // Save on destroy
  useEffect(() => {
    return () => {
      log.debug("setting unmount to true");
      isUnmountingRef.current = true;
    };
  }, []);
  useEffect(() => {
    return () => {
      log.debug("saving on unmount", isUnmountingRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (isUnmountingRef.current) {
        saveIfRequired();
      }
    };
  }, [saveIfRequired]);

  // On editor change
  useEffect(() => {
    if (editorRef.current !== editor) {
      log.info("editor changed. Has Editor", !!editor);
      editor && reset();
      editorRef.current = editor;
    }
  }, [editor, reset]);

  // on note contents updated on server
  useEffect(() => {
    if (!noteIdChanged) {
      log.info("loaded new text", {
        loadedNoteText,
        lasSavedValue: lastSavedValueRef.current,
      });

      // we need this to prevent multiple local saves from causing problems
      const timeSinceLastSave = Date.now() - lastSaveRef.current;
      if (
        loadedNoteText !== lastSavedValueRef.current &&
        timeSinceLastSave > 5000
      ) {
        resetEditText();
      }
    }
  }, [resetEditText, noteIdChanged, loadedNoteText]);

  // Reset the state when a different note is loaded
  useEffect(() => {
    if (!editor) return;

    if (noteIdChanged) {
      (async () => {
        reset();
      })();
    }
  }, [editor, reset, noteIdChanged]);

  /**
   * Changes to editor text.
   * @type {(function(*): void)|*}
   */
  const onChange = useCallback(
    (text) => {
      editedCountRef.current++;
      editedContentRef.current = text;
      log.debug("text changed", { text, count: editedCountRef.current });

      if (editedCountRef.current >= AUTO_SAVE_CHANGES) {
        editedCountRef.current = 0;
        saveIfRequired();
      }
    },
    [saveIfRequired]
  );

  return {
    onChange,
    performSave: saveIfRequired,
    revert: revertCallback,
    mobile,
    loaded: !!note && !isResetting,
  };
}
