import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import React from "react";

const StyledPopupMenu = styled.div`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  max-height: calc(100vh - 20px);
  min-width: 150px;
  background: ${(p) => p.theme.colors.primary[1]};
  padding: 12px 0;
  border-radius: ${(p) => p.theme.radii.default};
  border: 1px solid ${(p) => p.theme.colors.primary[0]};
  position: absolute;
  ${styleSystem()}
`;

const PopupMenu = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledPopupMenu {...props} ref={ref}>
      <ul>{children}</ul>
    </StyledPopupMenu>
  );
});
export default PopupMenu;

const StyledMenuItem = styled.div`
  cursor: pointer;

  :hover {
    background-color: ${(p) => p.theme.colors.primary[0]};
  }

  a {
    align-items: center;

    display: flex;
    font-size: 14px;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    line-height: normal;
    padding: 6px 20px;
    width: 100%;
    outline: none;
    position: relative;
  }
`;
export const MenuItem = ({ children, onClick, ...props }) => {
  function click(e) {
    onClick && onClick(e);
    e.preventDefault();
  }
  return (
    <StyledMenuItem {...props}>
      <li>
        <a onClick={click}> {children}</a>
      </li>
    </StyledMenuItem>
  );
};
