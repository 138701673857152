import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import FolderIcon from "@material-ui/icons/Folder";
const Wrapper = styled.div`
  border-radius: 5px;
  padding: 0.8em 1em 0.8em 0;
  background-color: lightblue;
  ul {
    list-style-type: none;
  }
  .tree {
    &__item {
      display: flex;
      align-items: center;
      padding: 0.3em 0.5em;
    }
    &__item:hover {
      background-color: lightgray;
    }
    &__label {
      margin-left: 1em;
    }
    &__section {
      padding-left: 1em;
    }
  }

  ${styleSystem()}
`;

export default function FolderTree({ nodes, ...props }) {
  if (!nodes) return null;
  return (
    <Wrapper {...props}>
      <Section nodes={nodes} />
    </Wrapper>
  );
}

function Section({ nodes }) {
  return (
    <ul className="tree__section">
      {nodes.map((x) => (
        <li>
          <div className="tree__item">
            <FolderIcon className="folder-icon" />{" "}
            <span className="tree__label">{x.label}</span>
          </div>
          {x.nodes?.length > 0 ? <Section nodes={x.nodes} /> : undefined}
        </li>
      ))}
    </ul>
  );
}
