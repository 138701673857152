import { useEffect } from "react";

export default function useDetectOutsideClick(cb, ...refs) {
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    const handler = (event) => {
      const isOutsideClick = !hasElementInPath(event, ...refs);
      cb && cb(isOutsideClick);
    };

    const eventNames = ["click", "touchstart", "touchend"];
    eventNames.forEach((evt) => body.addEventListener(evt, handler));
    return () => {
      eventNames.forEach((evt) => body.removeEventListener(evt, handler));
    };
  }, [cb, refs]);
}

function hasElementInPath(evt, ...refs) {
  if (evt.path) {
    return evt.path.some((x) => refs.some((r) => x === r.current));
  }
  let current = evt.target;
  // safari doesnt have a path list
  while (current) {
    // eslint-disable-next-line no-loop-func
    if (refs.some((r) => r.current === current)) return true;
    current = current.parentElement;
  }
  return false;
}
