import * as flexsearch from "flexsearch";
import { encode } from "flexsearch/src/lang/latin/simple";
import { createIdIndexMap } from "../../utils/idIndexMap";
import replaceHashPrefixInText from "../../utils/replaceHashPrefixInText";

const searchIndex = new flexsearch.Index({
  tokenize: "forward",
  // charset: null,
  encode: encode,
  optimize: true,
  resolution: 1,
  minlength: 3,
  threshold: 0,
  fastupdate: false,
  context: false,
});

const idMap = createIdIndexMap();

export function search(searchText, limit = 100, ...args) {
  searchText = replaceHashPrefixInText(searchText, false);

  return searchIndex
    .search(searchText, limit, ...args)
    .map(idMap.getIdForIndex);
}

export function add(id, value) {
  value = replaceHashPrefixInText(value, true);
  const idIndex = idMap.addToIndex(id);
  searchIndex.add(idIndex, value);
}

export function remove(id) {
  const index = idMap.getIndexForId(id);
  searchIndex.remove(index);
  idMap.removeById(id);
}

window.search = search;
