import axios from "axios";
import {
  getConfig,
  githubApiHostName,
  NOTES_PREFIX,
  parseGist,
} from "./common";
import { createLogger } from "../../../utils/log";
import { isNotFoundError } from "./noteApi";

const log = createLogger("gistApi");

export function getGistFileNames(gist) {
  return Object.keys(gist.files);
}

export async function getAllGistFiles(gist) {
  const filenames = getGistFileNames(gist);
  return await getGistFiles(gist, ...filenames);
}

export async function updateAttachments(id, files) {
  try {
    const response = await axios.patch(
      `${githubApiHostName}/gists/${id}`,
      { files },
      {
        ...getConfig(),
      }
    );
    return parseGist(response.data);
  } catch (err) {
    if (!isNotFoundError(err)) throw err;
  }
}

export async function getGistFiles(gist, ...files) {
  const data = {};
  for (let file of files) {
    if (gist.files[file]) {
      const url = gist.files[file].raw_url;
      const response = await axios.get(`${url}`, {
        ...getConfig({ noAuth: true }),
      });
      data[file] = response.data;
    } else {
      log.warn("File does not exist in gist", file);
    }
  }
  return data;
}

function mapContentToFiles(content) {
  const keys = Object.keys(content);
  const files = keys.reduce(
    (obj, key) => ({ ...obj, [key]: { content: content[key] } }),
    {}
  );
  return files;
}

export async function createGist({ description, files }) {
  files = mapContentToFiles(files);
  const data = {
    description,
    public: false,
    files,
  };
  const { data: gist } = await axios.post(`${githubApiHostName}/gists`, data, {
    ...getConfig(),
  });
  return parseGist(gist);
}

export async function updateGist({ id, description, files }) {
  description = description ? { description } : undefined;

  files = files && { files: mapContentToFiles(files) };

  const data = {
    ...description,
    ...files,
  };

  const response = await axios.patch(`${githubApiHostName}/gists/${id}`, data, {
    ...getConfig(),
  });
  return parseGist(response.data);
}

export async function getGists({ pageSize, pageNo, since }) {
  pageSize = pageSize || 100;
  pageNo = pageNo || 1;
  since = since || undefined;
  const response = await axios.get(`${githubApiHostName}/gists`, {
    ...getConfig({ params: { page: pageNo, per_page: pageSize, since } }),
  });
  if (response.data.length === 0) {
    return null;
  }
  return response.data
    .filter((x) => x.description.startsWith(NOTES_PREFIX))
    .map(parseGist);
}

export async function deleteGist(id) {
  try {
    const response = await axios.delete(`${githubApiHostName}/gists/${id}`, {
      ...getConfig(),
    });
    return response.data;
  } catch (err) {
    if (!isNotFoundError(err)) throw err;
  }
}

export async function getAllMyGists(since, callback) {
  // we have to add 1 else it will always retrieve the last note loaded
  since = (since || undefined) && new Date(since + 1).toJSON();
  for (let i = 1; ; i++) {
    const gists = await getGists({ pageSize: 100, pageNo: i, since });
    if (gists == null) break;
    await callback(gists);
  }
}

export async function getGithubUserInfo(pat) {
  const response = await axios.get(
    `${githubApiHostName}/user`,
    getConfig({ pat })
  );

  const { avatar_url, id, login, name, url } = response.data;
  return { avatar_url, id, login, name, url };
}
