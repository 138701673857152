import styled from "styled-components";

export const Pdf = ({ src }) => {
  return <>{<StyledObject data={src} />}</>;
};

const StyledObject = styled.object`

  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height:100%;
`;
